import {
  faChartUser,
  faClipboardUser,
  faComment,
  faHandHoldingMedical,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { Image } from '@quality24/design-system';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { HeadFC } from 'gatsby';
import * as React from 'react';

import SEO from '../../components/containers/SEO';
import Video from '../../components/atoms/Video';
import SolutionsTemplate from '../../components/templates/SolutionsTemplate';

import frame from '../../assets/macbook-frame.webp';
import poster from '../../assets/solutions/outpatient-poster.webp';
import video from '../../assets/videos/ambulatory.mp4';
import videoWebm from '../../assets/videos/ambulatory.webm';

import heroImage from '../../assets/solutions/outpatient0.webp';
import image1 from '../../assets/solutions/outpatient1.webp';
import image3 from '../../assets/solutions/outpatient3.webp';
import image4 from '../../assets/solutions/outpatient4.webp';
import * as styles from './ambulatorial.module.scss';

const OutpatientPage: React.FunctionComponent = () => {
  const breakpoints = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoints.lg === false, [breakpoints]);

  const HeroVideo = React.useMemo(
    () => (
      <div className={styles.laptop}>
        <Image className={styles.frame} src={frame} alt="laptop-frame" />
        <div className={styles.screen}>
          <Video
            className={styles.video}
            width={678}
            autoPlay={!isMobile}
            poster={poster}
            controls
            muted
            loop
          >
            <source src={videoWebm} type="video/webm" />
            <source src={video} type="video/mp4" />
            Painel ambulatorial
          </Video>
        </div>
      </div>
    ),
    [isMobile],
  );

  return (
    <SolutionsTemplate
      hero={{
        title: 'Ambulatorial',
        subtitle:
          'Otimize o trabalho da equipe e diminua o tempo de atendimento aos pacientes',
        icon: faHandHoldingMedical,
        image: HeroVideo,
      }}
      cards={[
        {
          icon: faClipboardUser,
          title: 'Gestão',
          content:
            'Reduza os tempos de espera e aumente a satisfação dos seus pacientes',
        },
        {
          icon: faChartUser,
          title: 'Dashboard',
          content:
            'Tenha visibilidade em tempo real de todos os pacientes em sua instituição',
        },
        {
          icon: faThumbsUp,
          title: 'Qualidade',
          content: 'Atendimento exclusivo e diferenciado com os pacientes',
        },
        {
          icon: faComment,
          title: 'Feedback do paciente',
          content:
            'Identifique e solucione os problemas rapidamente e diminua o número de detratores',
        },
      ]}
      steps={[
        {
          image: image1,
          content: 'Conecte, agilize e facilite a comunicação com o paciente',
        },
        {
          image: heroImage,
          content:
            'Acompanhe todas as etapas do atendimento ambulatorial em tempo real',
          width: '100%',
        },
        {
          image: image3,
          content:
            'Identifique os problemas e solucione com agilidade, antes mesmo do paciente deixar o hospital',
          width: '100%',
        },
        {
          image: image4,
          content:
            'Gestão eficiente: relatórios e indicadores sobre a eficiência ambulatorial',
          width: '100%',
        },
      ]}
    />
  );
};

export default OutpatientPage;

export const Head: HeadFC = () => (
  <SEO
    title="Ambulatorial | Soluções para Hospitais"
    description="Otimize o trabalho da equipe e diminua o tempo de atendimento aos pacientes"
    image={heroImage}
  />
);
